<template>
    <div>
        <b-card>
            <b-form @submit.prevent="save" class="account-form">
                <fieldset class="border-bottom pb-4">
                    <h4 class="mb-2">Loan Information</h4>
                    <div class="row" style="row-gap: 12px">
                        <div class="col-4">
                            <label for="DDLTypeLoan" class="mb-1">Loan Type</label>
                            <b-form-select
                                size="sm"
                                v-model="loanType"
                                id="DDLTypeLoan"
                                name="DDLTypeLoan"
                                v-on:change="changeLoanType()"
                                required
                            >
                                <b-form-select-option :value="null">Please select an option</b-form-select-option>
                                <b-form-select-option value="1">Personal</b-form-select-option>
                                <b-form-select-option value="2">Vehicle</b-form-select-option>
                            </b-form-select>
                        </div>
                        <div class="col-8"></div>

                        <div class="col-4">
                            <label for="LoanAmount" class="mb-1">Amount</label>
                            <b-input
                                size="sm"
                                type="number"
                                class="WhitePlaceHolder"
                                id="LoanAmount"
                                name="LoanAmount"
                                v-model="amount"
                                v-bind:min="minAmount"
                                v-bind:max="maxAmount"
                                v-on:input="debounce()"
                                placeholder=""
                                required
                            />

                            <span class="span" v-if="minAmount && maxAmount"
                                >Range: {{ minAmount }} - {{ maxAmount }}</span
                            >
                        </div>

                        <div class="col-4">
                            <label for="DDLPeriod" class="mb-1">Installments</label>
                            <b-form-select
                                size="sm"
                                v-model="monthSelectedOption"
                                id="DDLPeriod"
                                name="DDLPeriod"
                                v-on:change="getMonthlyPayment()"
                                required
                            >
                                <b-form-select-option v-for="month in monthsArray" :key="month" :value="month">{{
                                    month
                                }}</b-form-select-option>
                            </b-form-select>
                        </div>

                        <div class="col-4">
                            <label for="dynamicInterestRate" class="mb-1"> Dynamic interest rate </label>
                            <b-input
                                size="sm"
                                type="number"
                                class="WhitePlaceHolder"
                                id="dynamicInterestRate"
                                name="dynamicInterestRate"
                                v-model="dynamicInterestRate"
                                placeholder="0.00"
                                readonly
                            />
                        </div>

                        <div class="col-4">
                            <label for="MonthlyPayment" class="mb-1">
                                Monthly Payment
                                <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                            </label>
                            <b-input
                                size="sm"
                                type="number"
                                class="WhitePlaceHolder"
                                id="MonthlyPayment"
                                name="MonthlyPaCment"
                                v-model="monthlyPayment"
                                placeholder="AWG 0.00"
                                readonly
                            />
                        </div>

                        <div class="col-4">
                            <label for="fee" class="mb-1">
                                Expected administration fee
                                <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                            </label>
                            <b-input
                                size="sm"
                                type="number"
                                class="WhitePlaceHolder"
                                id="fee"
                                name="MonthlyPaCment"
                                :value="0"
                                placeholder="AWG 0.00"
                                readonly
                            />
                        </div>

                        <div class="col-4">
                            <label for="fee" class="mb-1"> Total expected amount </label>
                            <b-input
                                size="sm"
                                type="number"
                                class="WhitePlaceHolder"
                                id="fee"
                                name="MonthlyPaCment"
                                :value="amount"
                                placeholder="AWG 0.00"
                                readonly
                            />
                        </div>

                        <div class="col-4">
                            <label for="fee" class="mb-1"> APR </label>
                            <b-input
                                size="sm"
                                type="text"
                                class="WhitePlaceHolder"
                                id="fee"
                                v-model="apr"
                                name="MonthlyPaCment"
                                placeholder="0"
                                readonly
                            />
                        </div>
                    </div>
                </fieldset>
                <fieldset class="border-bottom py-4">
                    <div class="row" style="row-gap: 12px">
                        <div class="col-4">
                            <label for="repayment" class="mb-1"> Repayment Method </label>
                            <!-- select -->
                            <b-form-select v-model="repaymentMethod" size="sm" id="repayment" name="repayment" required>
                                <b-form-select-option value="1">CASH</b-form-select-option>
                                <b-form-select-option value="2">POB</b-form-select-option>
                            </b-form-select>
                        </div>

                        <b-col sm="4" class="">
                            <label class="fw-medium mb-1" for="comp4">Purpose</label>
                            <b-form-group class="mb-0">
                                <b-form-select
                                    size="sm"
                                    required
                                    :options="purposes"
                                    text-field="description"
                                    value-field="id"
                                    v-model="purpose"
                                ></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="4" class="">
                            <label class="fw-medium mb-1" for="">Purpose Details</label>
                            <b-input
                                size="sm"
                                type="text"
                                id="purposeDetails"
                                name="purposeDetails"
                                v-model="purposeDetails"
                            />
                        </b-col>
                    </div>
                </fieldset>

                <fieldset class="py-4">
                    <div class="row">
                        <div class="col-6">
                            <label class="fw-medium mb-1" for="cus4">Reason Type</label>
                            <b-form-radio-group
                                v-model="reasonType"
                                :options="customersTypes"
                                class="mb-3 d-flex"
                                value-field="id"
                                text-field="name"
                                required
                                @change="clearCustomer"
                            ></b-form-radio-group>
                        </div>
                        <div class="col-6 d-flex justify-content-end">
                            <a href="/customers/new" target="_blank" class="mt-4" style="text-decoration: underline"
                                >New Customer</a
                            >
                        </div>
                        <div class="col-4">
                            <div class="mb-2">
                                <label>Search an {{ reasonType == 1 ? 'Customer' : 'Company' }}</label>
                                <SearchSelect
                                    :store="reasonType == 1 ? 'customer/get_allCustomers' : 'customer/get_allBusiness'"
                                    text="firstName"
                                    value="id"
                                    @option-selected="handleOption"
                                />
                            </div>
                        </div>

                        <div class="col-md-4" v-if="reasonType == 2 && customer">
                            <h5 v-if="reasonType == 2">Company Data</h5>

                            <div>
                                <label for="Identification" class="mb-1">Company ID</label>
                                <p v-if="customer.businessID">{{ customer.businessID }}</p>
                                <p v-else>No Available</p>
                            </div>
                            <div>
                                <label for="FirstName" class="mb-1">Company Name</label>
                                <p v-if="customer.businessName">{{ customer.businessName }}</p>
                                <p v-else>No Available</p>
                            </div>
                        </div>

                        <div class="col-md-4" v-if="customer">
                            <h5 v-if="reasonType == 2">Contact Person</h5>
                            <div>
                                <label for="Identification" class="mb-1">Customer ID</label>
                                <p>{{ customer.id }}</p>
                            </div>
                            <div>
                                <label for="FirstName" class="mb-1">First Name</label>
                                <p v-if="customer.firstName">{{ customer.firstName }}</p>
                                <p v-else>No Available</p>
                            </div>
                            <div>
                                <label for="LastName" class="mb-1">Last Name</label>
                                <p v-if="customer.lastName">{{ customer.lastName }}</p>
                                <p v-else>No Available</p>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <div class="row mt-4">
                    <div class="col-md-12 d-flex justify-content-end align-items-start">
                        <b-button type="submit" id="BtnSendForm" variant="primary" :disabled="loadingData">
                            <b-spinner small v-if="loadingData"></b-spinner>
                            <span v-else>Save</span>
                        </b-button>
                    </div>
                </div>
            </b-form>
        </b-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SearchSelect from '../../components/SearchSelect.vue';

export default {
    name: 'Apply',
    components: {
        SearchSelect,
    },
    data() {
        return {
            loanType: null,
            amount: null,
            monthsArray: [],
            monthSelectedOption: null,
            minAmount: null,
            maxAmount: null,
            monthlyPayment: 0,
            loading: false,
            loadingData: false,
            purpose: null,
            customers: [],
            customer: null,
            purposes: [],
            reasonType: 1,
            businessName: null,
            repaymentMethod: null,
            purposeDetails: null,
            apr: 26.50,
            dynamicInterestRate: 0,
        };
    },
    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            customersTypes: 'general/customersTypes/getcustomersTypes',
        }),
    },
    methods: {
        changeLoanType() {
            if (this.loanType == 2) {
                this.purpose = 14;
            }
            this.getMinAndMaxAmount();
        },

        async save() {
            //validate tcustiomer is selected
            if (!this.customer) {
                this.$bvToast.toast('Please select a customer.', {
                    title: 'Please!',
                    variant: 'warning',
                    solid: true,
                });
                return;
            }

            const payload = {
                loanTypeId: this.loanType,
                periodInMonths: this.monthSelectedOption,
                loanAmount: this.amount,
                apr: this.apr,
                repaymentMethod: this.repaymentMethod,
                purposeId: this.purpose,
                purposeDetails: this.purposeDetails,
                applicationDetails: '',
                agentId: this.user.id,
                customerId: this.customer.id,
                createdBy: this.user.id,
                origin: 2,
                isActive: true,
            };

            this.loadingData = true;
            const response = await this.$store.dispatch('loan/applications/add_loanApplication', payload);
            if (response) {
                const id = response.dataResult;
                this.$router.push(`/applications/list/${this.customer.id}?loanApplicationId=${id}`);
            }
            this.loadingData = false;
        },

        async getMinAndMaxAmount() {
            const response = await this.$store.dispatch('loan/applications/get_loanRange', this.loanType);
            this.minAmount = response.minAmount;
            this.maxAmount = response.maxAmount;
            this.amount = this.minAmount;
            await this.getMonths();
            this.monthSelectedOption = this.monthsArray[0];
            await this.getMonthlyPayment();
        },

        async getMonths() {
            const payload = {
                amount: this.amount,
                loanType: this.loanType,
            };
            const response = await this.$store.dispatch('loan/applications/get_monthsByLoanType', payload);
            if (!response && response.length < 1) return;
            this.monthsArray = response;
            this.monthSelectedOption = response[0];
            this.getMonthlyPayment();
        },
        async getMonthlyPayment() {
            this.loading = true;

            const payload = {
                amount: this.amount,
                months: this.monthSelectedOption,
                loanType: this.loanType,
                apr: this.apr,
            };
            const response = await this.$store.dispatch('loan/applications/get_monthlyPayment', payload);
            this.monthlyPayment = response.amount;
            this.apr = response.apr;
            this.dynamicInterestRate = response.dynamicInterestRate?.toFixed(2);
            this.loading = false;
        },
        debounce() {
            if (this.loanType == null) {
                this.$bvToast.toast('Please select a loan type.', {
                    title: 'Please!',
                    variant: 'warning',
                    solid: true,
                });
                return;
            }

            this.loading = true;
            clearTimeout(this.inputTimer);
            this.inputTimer = setTimeout(async () => {
                if (this.amount >= this.minAmount && this.amount <= this.maxAmount) {
                    this.getMonths();
                    this.getMonthlyPayment();
                } else {
                    let LoanTypeDescription = this.loanType == 1 ? 'Personal' : 'Vehicle';
                    if (this.amount < this.minAmount) {
                        this.$bvToast.toast(`Our ${LoanTypeDescription} Loans start at AWG ${this.minAmount}.`, {
                            title: 'Please!',
                            variant: 'warning',
                            solid: true,
                        });
                        this.amount = this.minAmount;
                        await this.getMonths();
                        this.monthSelectedOption = this.monthsArray[0];

                        this.getMonthlyPayment();
                    } else if (this.amount > this.maxAmount) {
                        this.$bvToast.toast(
                            `You have exceeded the maximum loan amount. Our ${LoanTypeDescription} Loans go up to ${this.maxAmount}.`,
                            {
                                title: 'Please!',
                                variant: 'warning',
                                solid: true,
                            },
                        );
                        this.amount = this.maxAmount;
                        this.getMonths();
                        this.getMonthlyPayment();
                    }
                }
            }, 1500);
        },
        handleOption(item) {
            if (item) {
                this.customer = item;
            }
        },
        async getPurposes() {
            const response = await this.$store.dispatch('loan/applications/get_purposes');
            this.purposes = response?.dataResult ?? [];
        },
        async getCustomerTypes() {
            await this.$store.dispatch('general/customersTypes/get_allCustomersTypes', { skip: 1, take: 9999 });
        },
        clearCustomer() {
            this.customer = null;
        },
    },
    created() {
        this.getPurposes();
        this.getCustomerTypes();
    },
};
</script>

<style>
label {
    color: #666666 !important;
    font-weight: 600;
}
</style>
